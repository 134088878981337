@import '../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    z-index: 1;
    position: relative;
    background-color: var(--backgroundColorLight);

    @media (--viewportMedium) {
        padding: 75px;
        min-height: 780px;
    }
}

.root::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('./images/sell-backgorund.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;

    @media (--viewportMedium) {
        width: calc(100% - 150px);
        height: calc(100% - 150px);
    }
}

.title {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: var(--colorWhite);
    padding: 0 12px 0 0;
    margin: 0;
    text-transform: uppercase;

    @media (--viewportMedium) {
        font-size: 48px;
        line-height: 90px;
    }
}

.titleBold {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: var(--colorWhite);
    padding: 0;
    margin: 0;
    text-transform: uppercase;

    @media (--viewportMedium) {
        font-size: 48px;
        line-height: 90px;
    }
}

.text {
    font-style: normal;
    font-weight: 500;
    color: var(--colorWhite);
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    padding: 0;
    margin: 0;
    max-width: 700px;

    @media (--viewportMedium) {
        font-size: 24px;
        line-height: 38px;
    }
}

.button {
    background-color: transparent;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColorDark);
    border-radius: 0;
    min-width: 180px;
    margin-top: 15px;
    min-height: 40px;

    &:hover {
        color: var(--colorWhite);
    }
}

.linkContainer {
    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }
}