@import '../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    padding: 15px 0 0 0;
    margin: 0;
    height: 50vh;
    background-color: var(--colorWhite);

    @media (--viewportMedium) {
        height: unset;
        padding: 140px 0 0 0;
        margin-bottom: -10px;
    }
}

.backgroundImage {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
        url('./images/new.png');
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (--viewportMedium) {
        aspect-ratio: 2.4 / 1;
    }
}

/* .root::before {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% - 10px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
        url('./images/new.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;

    @media (--viewportMedium) {
        height: calc(100% - 100px);
    }
} */

.title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: var(--colorWhite);
    padding: 0;
    margin: 0;
    text-transform: uppercase;

    @media (--viewportMedium) {
        font-size: 48px;
        line-height: 59px;
    }
}

.info {
    font-style: normal;
    font-weight: 500;
    color: var(--colorWhite);
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    padding: 0;
    margin: 0;
    max-width: 360px;

    @media (--viewportMedium) {
        font-size: 24px;
        line-height: 34px;
        max-width: 700px;
    }
}

.button {
    margin-top: 15px;
}